import { Route, Routes } from "react-router-dom";

// Blocks Layout
import ArmSTQBHome from "./pages/ArmSTQBHome";
import CertificateDetails from "./sub-pages/certificates/CertificateDetails";
import Certifications from "./pages/Certifications";
import AboutISTQB from "./pages/AboutISTQB";
import Partners from "./pages/Partners";
import Events from "./pages/Events";
import News from "./pages/News";
import FAQ from "./pages/FAQ";
import VoloNews from "./sub-pages/news/volo_04_12_2023/VoloNews";
import SynergyNews from "./sub-pages/news/synergy_07_12_2023/SynergyNews";
import CTFLBookNews from "./sub-pages/news/CTFLBook_19_01_2024/CTFLBookNews";
import AIExamNews from "./sub-pages/news/AIExam_23_01_2024/AIExamNews";
import ArmsoftNews from "./sub-pages/news/armsoft_21_06_2024/ArmsoftNews";
import ZealousNews from "./sub-pages/news/zealous_27_06_2024/ZealousNews";
import ExamsEvent from "./sub-pages/events/examsEvent_15_01_2024/ExamsEvent";
import DiscountedExamsEvent from "./sub-pages/events/discountedExamsEvent_08_07_2024/DiscountedExamsEvent";
// import QASummerCampEvent from "./sub-pages/events/QASummerCampEvent_08_07_2024/QASummerCampEvent";
import TestersDayPartyEvent from "./sub-pages/events/testersDayPartyEvent_09_08_2024/TestersDayPartyEvent";
import SummerMeetUpNews from "./sub-pages/news/summerMeetup_07_06_2024/SummerMeetUpNews";
import InnoFestHackathonNews from "./sub-pages/news/innoFestHackathon_28_07_2024/InnoFestHackathonNews";
import Contact from "./pages/Contact";
import Error404 from "./pages/Error404";
import QASummerCampNews from "./sub-pages/news/QASummerCamp_06_08_2024/QASummerCampNews";

export default function App() {
	return (
		<>
			<Routes>
				<Route exact path={`${process.env.PUBLIC_URL}/`} element={<ArmSTQBHome />} />
				<Route exact path={`${process.env.PUBLIC_URL}/about-istqb`} element={<AboutISTQB />} />
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/certifications`}
					element={<Certifications />}
				/>
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/certifications/certified-tester-foundation-level-v3-1`}
					element={<CertificateDetails exam={"ctfl3.1"} />}
				/>
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/certifications/certified-tester-foundation-level`}
					element={<CertificateDetails exam={"ctfl4"} />}
				/>
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/certifications/agile-tester`}
					element={<CertificateDetails exam={"ctat"} />}
				/>
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/certifications/test-manager-2012`}
					element={<CertificateDetails exam={"ctal-tm"} />}
				/>
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/certifications/test-manager`}
					element={<CertificateDetails exam={"ctal-tm3"} />}
				/>
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/certifications/test-analyst`}
					element={<CertificateDetails exam={"ctal-ta"} />}
				/>
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/certifications/technical-test-analyst`}
					element={<CertificateDetails exam={"ctal-tta"} />}
				/>
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/certifications/artificial-inteligence-tester`}
					element={<CertificateDetails exam={"ct-ai"} />}
				/>
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/certifications/gambling-tester`}
					element={<CertificateDetails exam={"ct-gt"} />}
				/>
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/certifications/mobile-tester`}
					element={<CertificateDetails exam={"ct-mat"} />}
				/>
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/certifications/security-tester`}
					element={<CertificateDetails exam={"ctal-sec"} />}
				/>
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/certifications/test-automation-engineer-2016`}
					element={<CertificateDetails exam={"ctal-tae"} />}
				/>
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/certifications/test-automation-engineer`}
					element={<CertificateDetails exam={"ctal-tae2"} />}
				/>
				<Route exact path={`${process.env.PUBLIC_URL}/partners`} element={<Partners />} />
				<Route exact path={`${process.env.PUBLIC_URL}/events`} element={<Events />} />
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/events/istqb-certification-exams-of-2024`}
					element={<ExamsEvent />}
				/>
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/events/discount-on-istqb-exams`}
					element={<DiscountedExamsEvent />}
				/>
				{/* <Route
					exact
					path={`${process.env.PUBLIC_URL}/events/qa-summer-camp`}
					element={<QASummerCampEvent />}
				/> */}
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/events/testers-day-party`}
					element={<TestersDayPartyEvent />}
				/>
				<Route exact path={`${process.env.PUBLIC_URL}/news`} element={<News />} />
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/news/silver-partnership-with-volo`}
					element={<VoloNews />}
				/>
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/news/silver-partnership-with-synergy`}
					element={<SynergyNews />}
				/>
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/news/ctfl-self-study-guide-release`}
					element={<CTFLBookNews />}
				/>
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/news/ai-testing-specialist-exam-available-in-armstqb`}
					element={<AIExamNews />}
				/>
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/news/extended-partnership-with-armsoft`}
					element={<ArmsoftNews />}
				/>
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/news/extended-partnership-with-zealous`}
					element={<ZealousNews />}
				/>
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/news/summer-meetup-1`}
					element={<SummerMeetUpNews />}
				/>
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/news/innofest-hackathon`}
					element={<InnoFestHackathonNews />}
				/>
				<Route
					exact
					path={`${process.env.PUBLIC_URL}/news/qa-summer-camp`}
					element={<QASummerCampNews />}
				/>
				<Route exact path={`${process.env.PUBLIC_URL}/faqs`} element={<FAQ />} />
				<Route exact path={`${process.env.PUBLIC_URL}/contact`} element={<Contact />} />
				<Route path="*" element={<Error404 />} />
			</Routes>
		</>
	);
}
